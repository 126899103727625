<template>
  <div class="home">
    <div class="home_top">
      <div class="home_top_logo">
        <img src="@/assets/images/logo.png" alt="">
        <span class="home_top_title">任务悬赏平台</span>
      </div>
    </div>
    <div class="home-content">
        <div class="home_content_core">
          <div class="home_content_logo">
            <img src="@/assets/images/center-logo.png" alt="">
          </div>
          <component
            :is="componentName"
            @change-form="changeForm">
          </component>
        </div>
    </div>
    <div class="home-bottom"></div>
  </div>
</template>

<script>
import Forget from '../login/forget-reg/forget.vue'
import Register from '../login/forget-reg/register.vue'
import Login from '../login/forget-reg/login.vue'
export default {
  components: {
    Forget,
    Register,
    Login
  },
  data () {
    return {
      dialogValue: true,
      componentName: 'login'
    }
  },
  methods: {
    changeForm (tab) {
      this.componentName = tab
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  min-width: 1218px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .home_top{
  width:100%;
  background-color: #FFFFFF;
  height: 60px;
  border-radius: 0px;
  .home_top_logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 60px;
    padding-left: 20px;
    .home_top_title {
      padding-left: 10px;
      font-size: 20px;
      color: #000;
      font-weight: bold;
    }
  }
 }
 .home-content {
    min-height: 749px;
    background-color: #F3F3F3;
    flex:1;
    .home_content_core {
      width: 100%;
      display: flex;
      justify-content: center;
      .home_content_logo {
        width:718px;
        height: 650px;
      }
  }
 }
  .home-bottom {
    width: 100%;
    height: 160px;
    background: #313131;
    border-radius: 0px;
  }
}

</style>
