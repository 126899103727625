<template>
  <div class="form">
    <div class="forget-pws">
        <div class="login">
            <div class="login_title">
              <button
                class="forget-coin"
                @click="$emit('change-form','login')">
                &lt;
              </button>
              <p
                class="tw-text-[20px]
                forget-title">
                注册账号
              </p>
            </div>
            <div class="login_form">
              <el-form
                ref="form"
                :model="form"
                :rules="rules">
                <el-form-item prop="user">
                  <el-input
                    type="text"
                    placeholder="请输入用户名"
                    v-model.trim="form.user">
                  </el-input>
                </el-form-item>
                <el-form-item prop="mobile">
                    <el-input
                      style="width:320px;"
                      type="text"
                      :maxlength="11"
                      placeholder="请输入手机号"
                      v-model.trim="form.mobile">
                    </el-input>
                </el-form-item>
                <el-form-item prop="code">
                  <el-row type="flex">
                    <el-input
                      type="text"
                      v-model.trim="form.code"
                      placeholder="请输入短信验证码">
                    </el-input>
                    <el-button
                      type="primary"
                      size="small"
                      :loading="sendSmsLoading"
                      @click="handleSendSms"
                      :disabled="!(canGetSms && isMobileValid)">
                      {{ sendSmsBtnText }}
                    </el-button>
                  </el-row>
                </el-form-item>
                <el-form-item
                  prop="password"
                  @keydown.native.enter="handleLogin">
                  <el-input
                    type="password"
                    v-model.trim="form.password"
                    placeholder="请输入密码"
                    show-password>
                  </el-input>
                </el-form-item>
                <el-form-item prop="role">
                  <el-select v-model="form.role"
                    placeholder="请选择用户"
                    style="width:320px;">
                    <el-option
                      label="用户"
                      value="0">
                    </el-option>
                    <el-option
                      label="企业"
                      value="1">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <el-button
                type="primary"
                style= "width:320px;"
                :loading="submitBtnLoading"
                @click="submitForm">
                立即注册
              </el-button>
            </div>
            <div class="login-message">
              <span>当前平台处于试运营期间，如有问题，可联系本公司工作人员反馈</span>
            </div>
            <div class="content-logo-hidden">
              <img src="@/assets/images/content-logo-hidden.png" alt="">
            </div>
            <div class="home-content-right">
              <img src="@/assets/images/center-right.png" alt="">
            </div>
            <div class="right-hidden">
              <img src="@/assets/images/right-hidden.png" alt="">
            </div>
          </div>

    </div>
  </div>
</template>

<script>
import api from '@/api'
import SHA256 from 'crypto-js/sha256'
export default {
  data () {
    return {
      form: {
        user: '', // 用户名
        mobile: '', // 手机号
        code: '', // 短信验证码
        password: '', // 密码
        role: '' // 选择类型
      },
      rules: {
        role: [
          { required: true, message: '请选择用户', trigger: 'blur' }
        ],
        user: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1\d{10}$/, message: '手机号格式错误', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入短信验证码', trigger: 'blur' },
          { pattern: /^[0-9]{6}$/, message: '验证码格式错误', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { pattern: /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d]{8,16}$/, message: '密码长度为8~16位，至少需要包含数字、字母', trigger: 'blur' }
        ]
      },
      sendSmsBtnText: '获取验证码', // 获取短信验证码按钮文字
      sendSmsLoading: false, // 获取短信验证码loading
      canGetSms: true, // 是否可以获取短信验证码
      submitBtnLoading: false // 注册loading
    }
  },
  computed: {
    // 对手机号进行验证
    isMobileValid () {
      return this.form.mobile && /^1\d{10}$/.test(this.form.mobile)
    }
  },
  methods: {
    // 获取短信验证码
    handleSendSms () {
      if (!(this.canGetSms && this.isMobileValid)) return
      this.canGetSms = false
      this.sendSmsLoading = true
      api.sendSmsCode({
        mobile: this.form.mobile
      }).then(res => {
        this.sendSmsLoading = false
        if (res.data.code === 0) {
          let count = 30
          this.sendSmsBtnText = `已发送(${count}s)`
          const interval = setInterval(() => {
            if (count <= 0) {
              clearInterval(interval)
              this.sendSmsBtnText = '发送验证码'
              this.canGetSms = true
            } else {
              count--
              this.sendSmsBtnText = `已发送(${count}s)`
            }
          }, 1000)
        } else {
          this.canGetSms = true
          this.$message.error('验证码发送失败')
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求出错')
        this.canGetSms = true
        this.sendSmsLoading = false
      })
    },
    submitForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitBtnLoading = true
          api.register({
            username: this.form.user,
            mobile: this.form.mobile,
            password: SHA256(this.form.password).toString(),
            code: this.form.code,
            role: this.form.role
          }).then(res => {
            this.submitBtnLoading = false
            if (res.data.code === 0) {
              this.$message.success('注册成功，请登录')
              this.$emit('change-form', 'login')
            } else {
              this.$message.error(res.data.message)
            }
          }).catch((error) => {
            this.submitBtnLoading = false
            console.log(error)
            this.$message.error('请求出错')
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
      position: relative;
      top: 50px;
      width:500px;
      height: 600px;
      padding: 50px 90px;
      border: 1px solid #fff;
      background-color:rgba(254, 254, 254, 1);
      border-radius: 4px;
      .login_title {
        width: 210px;
        height: 28px;
        font-size: 20px;
        font-weight: bold;
        line-height: 0px;
        color: #000000;
        display: flex;
        align-items: center;
        .forget-coin {
          width: 16px;
          height: 16px;
          color: #000000;
        }
        .forget-title {
          margin-left: 10px;
        }
      }
      .login_form {
          margin-top: 36px;
        }
      .login-message {
        position:absolute;
        bottom:36px;
        width: 320px;
        height: 60px;
        font-size: 12px;
        font-weight: 400;
        padding: 10px 10px 0px 10px;
        color: #909399;
        border-top: 1px solid #DCDFE6;
      }
      .content-logo-hidden{
        position: absolute;
        width: 80px;
        top: 0px;
        left: -1px;
      }
      .home-content-right {
        position: absolute;
        right:-96px;
        bottom: -100px;
      }
      .right-hidden {
        position: absolute;
        right: 0px;
        bottom:0px;
      }
    }
</style>
